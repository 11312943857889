export class MessageScanner {
  constructor() {
    // Core URL detection patterns
    this.urlPattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
    this.nonAsciiUrlPattern = /((https?:\/\/|www\.)[\S]*[^\u0020-\u007F]+[\S]*)|([^\u0020-\u007F]+(\.[^\u0020-\u007F]+)+[\S]*)/gi;

    // Unicode confusables detection
    this.confusablePattern = /[\u0430\u0435\u043E\u0440\u0441\u0443\u0445\u04CF\u0443\u0456\u0261\u0180\u0274\u03F3\u0441\u0454\u0438]/g;

    // Command patterns that suggest malicious intent
    this.commandPatterns = {
      copyPaste: /\b(copy|paste|type|enter)\s*(this|url|link|address)?\s*(in|into|to)?\s*(your|the)?\s*(browser|address\s*bar)\b/i,
      visitNow: /\b(visit|go\s*to|check|access)\s*(this|the|url|link|site|page)\s*(now|immediately|asap)\b/i,
      openLink: /\b(open|launch|start)\s*(this|the|url|link|site|page)\b/i,
      redirect: /\b(redirect|forward|navigate)\s*(to|towards)?\s*(this|the|url|link|site|page)\b/i,
    };

    // Comprehensive suspicious patterns
    this.suspiciousPatterns = {
      // Verification and authentication patterns
      sellerVerification: /\b(mandatory|required|must\s*complete)\s*(seller\s*verification|verify\s*seller|seller\s*status)\b/i,
      accountVerification: /\b(verify|confirm|validate|update)\s*(your\s*)?(account|details|identity|information|profile)\s*(now|immediately|urgently|or\s*else)\b/i,
      adminVerification: /\b(admin|moderator|support|team)\s*(verification|message|request|notice|alert)\s*(required|mandatory|immediate)\b/i,
      bankVerification: /\b(bank\s*verification\s*required|verify\s*bank\s*(details|account|info)\s*(immediately|urgently|or\s*else))\b/i,

      // Financial and payment patterns
      payoutVerification: /\b(payout|payment)\s*(verification|setup)\s*(required|needed)\s*(immediately|before|to\s*continue)\b/i,
      payoutPromise: /\b(start|begin|enable|activate)\s*(receiving|getting)\s*(payments?|payouts?|money)\b/i,
      moneyTransfer: /\b(transfer|send|receive)\s*(money|funds|payments?|amount)\b/i,

      // Urgency and pressure patterns
      // urgentAction: /\b(urgent|immediate(ly)?|action\s*required)\s*(verification|confirmation|response|or\s*(?:account|access)\s*will\s*be)\b/i,
      // forcedAction: /\b(account\s*(suspended|on\s*hold|blocked|limited)|immediate\s*suspension|cannot\s*(process|continue)|restricted\s*access)\s*(until|unless|if\s*not)\b/i,
      // timeConstraint: /\b(must|required|need)\s*(verify|confirm|complete)\s*within\s*\d+\s*(hours?|minutes?|days?)\s*or\b/i,

      // Request and instruction patterns
      suspiciousRequest: /\b(kindly|please|urgently)\s*(verify|confirm|update|provide)\s*(your|account|password|login|details)\s*(now|asap|immediately|or\s*else)\b/i,
      instructionSequence: /\b(follow|complete|do)\s*(these|the|following)\s*(steps|instructions|process)\b/i,
      actionCommand: /\b(you\s*must|you\s*need\s*to|required\s*to)\s*(do|perform|complete|follow)\b/i,

      // UTurn-specific patterns
      uturnClaim: /\b(i'?m?\s*(from|an?|the)|this\s*is)\s*(the|an?)?\s*uturn\s*(admin|staff|support|team|representative|moderator|employee)\s*(requesting|requiring|demanding)\b/i,
      uturnOfficial: /\b(official|authorized|verified)\s*uturn\s*(admin|staff|support|representative|moderator|employee)\s*(notice|warning|alert)\b/i,
      uturnMessage: /\b(urgent|important)\s*(message|notice|alert|update|notification)\s*(from|by)\s*uturn\s*(admin|staff|support|team)\b/i,
      uturnBranding: /\b(uturn\s*:\s*(urgent|important|warning|alert|verify|confirm))/i,

      // Security and access patterns
      passwordReset: /\b(reset|recover|restore|change)\s*(your\s*)?(password|login|credentials|access)\s*(now|immediately|or\s*(?:account|access)\s*will)\b/i,
      securityAlert: /\b(security|suspicious|unusual|unauthorized)\s*(alert|warning|notice|activity|access)\s*(verify|confirm|update)\s*(now|immediately)\b/i,
      accountSecurity: /\b(protect|secure|safeguard)\s*(your|the)\s*(account|information|details)\s*(now|immediately|or\s*risk)\b/i,

      // Data collection patterns
      sensitiveDataRequest: /\b(send|provide|update|confirm)\s*(your\s*)?(ssn|tax\s*id|passport|driver'?s?\s*license|credit\s*card|banking\s*info)\s*(now|immediately|urgently)\b/i,
      detailsRequest: /\b(provide|submit|enter|input)\s*(your|the|account)?\s*(details|information|data)\b/i,

      // Link and browser manipulation
      clickAction: /\b(click|visit|access|follow)\s*(here|link|form|verification|this)\s*(now|immediately|or\s*(?:account|access)\s*will)\b/i,
      documentRequest: /\b(download|open|view)\s*(the|this)?\s*(document|attachment|file|form)\s*(now|immediately|or\s*(?:account|access)\s*will)\b/i,
      browserAction: /\b(browser|address\s*bar|url\s*bar|webpage)\s*(access|enter|visit)\b/i,

      // Text manipulation and formatting
      obfuscatedText: /([A-Za-z][\u0300-\u036F]+|[\u0400-\u04FF])/g,
      suspiciousSpacing: /\s{2,}|\t+/g,

      scamDearSellerReady: [
        /dear\s+seller/i,
        /fraud\s+on\s+uturn/i,
        /verify\s+(your\s+)?personal\s+details/i,
        // You can tweak how you want to detect “write ‘Ready’ in the chat box”
        /write\s+[‘'"]?ready[’'"]?\s+(in\s+)?(the\s+)?chat\s+box/i,
      ],
    };

    // Whitelist for legitimate business communications
    this.whitelist = [
      // Shipping and delivery
      /\b(shipping|sending|mailing)\s*(this|it|package|item|product)?\s*(to\s*you)?\s*(today|now|immediately|asap)\b/i,
      /\b(package|order|item)\s*(will|should)\s*(arrive|be\s*delivered|ship)\s*(today|tomorrow|soon|asap)\b/i,

      // Normal business communications
      /\b(i'?m?\s*(sorry|apologies|late|moving|busy))\b/i,
      /\b(will\s*send\s*later|check\s*this\s*morning|forgot\s*to\s*reply)\b/i,
      /\b(can'?t\s*check\s*right\s*now|follow\s*up\s*later)\b/i,
      /\b(thank\s*you\s*for\s*your\s*patience|will\s*get\s*back\s*to\s*you)\b/i,

      // Legitimate transaction messages
      /\b(payment\s*sent|i\s*paid|transferred\s*the\s*money|sent\s*the\s*payment)\b/i,
      /\b(i\s*received|got)\s*(the\s*)?(payment|money|transfer)\b/i,

      // Normal urgent business matters
      /\b(need|want)\s*this\s*(asap|quickly|soon|now)\b/i,
      /\b(can\s*you\s*)?check\s*(this|it)\s*(asap|immediately|urgently)\b/i,
      /\b(rushing|hurrying)\s*to\s*(send|get|finish)\b/i,
    ];
  }

  /**
   * Scans a message for suspicious content and potential security threats.
   * @param {string} message - The message to scan
   * @returns {Object} Analysis results including threats found and severity level
   */
  scanMessage(message) {
    const results = {
      containsUrls: false,
      urls: [],
      suspiciousContent: [],
      hasWarnings: false,
      hasUTurnClaims: false,
      severity: 'low',
      combinedPatterns: [],
      hasConfusables: false,
      hasCommandPatterns: false,
      obfuscationScore: 0,
      analysis: {},
    };

    // 1) Check for Unicode confusables
    const confusablesCount = (message.match(this.confusablePattern) || []).length;
    if (confusablesCount > 0) {
      results.hasConfusables = true;
      results.obfuscationScore += confusablesCount;
      results.suspiciousContent.push({
        type: 'unicodeConfusables',
        pattern: 'Unicode confusable characters detected',
        matchedText: 'Message contains look-alike characters',
        count: confusablesCount,
      });
    }

    // 2) Check for command patterns
    for (const [key, pattern] of Object.entries(this.commandPatterns)) {
      if (pattern.test(message)) {
        results.hasCommandPatterns = true;
        results.suspiciousContent.push({
          type: 'commandPattern',
          subtype: key,
          pattern: pattern.toString(),
          matchedText: message.match(pattern)[0],
        });
      }
    }

    // 3) Whitelist check (skip if confusables or command patterns found)
    if (!results.hasConfusables && !results.hasCommandPatterns) {
      for (const whitelistPattern of this.whitelist) {
        if (whitelistPattern.test(message)) {
          const matchedText = message.match(whitelistPattern)[0];
          if (matchedText.length / message.length > 0.7) {
            return results;
          }
        }
      }
    }

    // 4) URL checks
    const asciiUrls = message.match(this.urlPattern) || [];
    const nonAsciiUrls = message.match(this.nonAsciiUrlPattern) || [];
    const allUrls = [...asciiUrls, ...nonAsciiUrls];

    if (allUrls.length > 0) {
      results.containsUrls = true;
      results.urls = allUrls;
      results.obfuscationScore += nonAsciiUrls.length * 2;
    }

    // 5) Suspicious pattern checks with weighted scoring
    let suspiciousScore = 0;
    let weightedPatterns = {};

    for (const [key, pattern] of Object.entries(this.suspiciousPatterns)) {
      const matches = message.match(pattern);
      if (matches) {
        const matchInfo = {
          type: key,
          pattern: pattern.toString(),
          matchedText: matches[0],
        };

        results.suspiciousContent.push(matchInfo);

        // Weight different types of patterns
        let patternWeight = 1;

        // if (key.includes('uturn')) {
        //   patternWeight = 2;
        //   results.hasUTurnClaims = true;
        // }
        // if (key.includes('bank') || key.includes('payment') || key.includes('payout')) {
        //   patternWeight = 2;
        //   weightedPatterns.financial = true;
        // }
        if (key.includes('urgent') || key.includes('immediate')) {
          patternWeight = 1.5;
          weightedPatterns.urgent = true;
        }
        // if (key.includes('verify') || key.includes('confirm')) {
        //   patternWeight = 1.5;
        //   weightedPatterns.verification = true;
        // }
        if (key.includes('password') || key.includes('security')) {
          patternWeight = 2;
          weightedPatterns.security = true;
        }

        suspiciousScore += patternWeight;
        results.combinedPatterns.push(key);
      }
    }

    // 6) Calculate final severity
    const totalScore = suspiciousScore + results.obfuscationScore;

    results.hasWarnings =
      totalScore > 2 ||
      results.hasConfusables ||
      results.hasCommandPatterns ||
      (weightedPatterns.financial && weightedPatterns.urgent) ||
      (results.hasUTurnClaims && weightedPatterns.verification);

    if (
      totalScore >= 5 ||
      (results.hasConfusables && results.hasCommandPatterns) ||
      (results.hasUTurnClaims && weightedPatterns.financial) ||
      (weightedPatterns.financial && weightedPatterns.urgent && weightedPatterns.verification)
    ) {
      results.severity = 'high';
    } else if (
      totalScore >= 3 ||
      results.hasConfusables ||
      (results.containsUrls && results.hasCommandPatterns) ||
      (weightedPatterns.financial && weightedPatterns.urgent)
    ) {
      results.severity = 'medium';
    }

    //loop through the results.suspiciousContent and check if one of them is "obfuscatedText" if so set haswarnings to true

    results.suspiciousContent.forEach(element => {
      if (element.type === 'obfuscatedText') {
        results.hasWarnings = true;
      } else if (element.type === 'bankVerification' || element.type === 'scamDearSellerReady') {
        results.hasWarnings = true;
      }
    });

    // 7) Add analysis metadata
    results.analysis = {
      totalScore: totalScore,
      obfuscationScore: results.obfuscationScore,
      suspiciousScore: suspiciousScore,
      confusablesFound: confusablesCount,
      messageLength: message.length,
      patternCategories: Object.keys(weightedPatterns),
    };

    return results;
  }

  /**
   * Adds a new pattern to the suspicious patterns list
   * @param {string} name - Name of the pattern
   * @param {RegExp|string} pattern - The pattern to add
   */
  addPattern(name, pattern) {
    if (pattern instanceof RegExp) {
      this.suspiciousPatterns[name] = pattern;
    } else if (typeof pattern === 'string') {
      this.suspiciousPatterns[name] = new RegExp(pattern, 'i');
    }
  }

  addToWhitelist(pattern) {
    if (pattern instanceof RegExp) {
      this.whitelist.push(pattern);
    } else if (typeof pattern === 'string') {
      this.whitelist.push(new RegExp(pattern, 'i'));
    }
  }
}
